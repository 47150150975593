.folder-container {
    display: flex;
    align-items: center;
    gap: 20px;

    .folder-content {
        width: 100%;
        background-color: #2b2b2b;
        padding: 15px;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
    }

    svg {
        font-size: 30px;
    }
}