$bg-gradint-color: linear-gradient(45deg, rgba(255, 107, 107, 0.6196078431), #712fe5);
// $logo-gradint-color: linear-gradient(45deg, #ff6b6b9e, #712fe5);
$logo-gradint-color: linear-gradient(331deg, rgb(102, 126, 234) 10%, rgb(118, 75, 162) 90%);
$component-bg-color: #282828;

.logo-box {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  letter-spacing: 1px;
  font-weight: bold;
  // background-image: $logo-gradint-color;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  position: relative;
  display: flex;
  justify-content: space-around;
  gap: 10px;
  color: #1247aa;
  user-select: none;
}

nav {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 20px 0;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  z-index: 1;
  background-color: #1d1d1d;
  box-shadow: inset 0px -1px 1px #2f2f2f;

  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .nav-right-content {
      display: flex;
      gap: 20px;
      margin-right: 10px;

      .dropdown-container {
        position: relative;
        width: 120px;

        button svg {
          font-size: 20px;
        }

        .dropdown {
          position: absolute;
          box-shadow: 1px 1px 6px #000;
          background-color: #222222;

          button {
            border-radius: unset;
            width: 100%;
          }
        }
      }
    }
  }

  .side-menu {
    position: fixed;
    top: 25%;
    left: 0;
    background-color: $component-bg-color;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    .side-nav-about-link {
      display: none;
    }

    ul {
      padding-left: 0;
      margin: 10px 5px;
      margin-left: 0;

      a {
        color: #fff;
        text-decoration: none;
      }

      li {
        list-style: none;
        font-size: 30px;
        margin: 5px;
        padding: 10px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: #1d1d1d;
          border-radius: 5px;
        }

        .user-profile-container {
          display: flex;

          img {
            width: 35px;
            border-radius: 100%;
          }
        }
      }
    }

    .active-link {
      position: relative;

      li {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background: #1d1d1d;
        // background: linear-gradient(90deg, #1f1f4a, #3c3c7a);

        svg {
          // animation: floatingIcon 0.5s ease-in-out infinite;
          position: relative;
          top: 0;
          color: #719ff3;
        }
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 3px;
        // background-image: $logo-gradint-color;
        background-color: #719ff3;
        transform: scaleY(0);
        transform-origin: top;
        animation: lineMovement 0.3s ease-in-out forwards;
      }
    }

    // @keyframes floatingIcon {

    //   0%,
    //   100% {
    //     transform: translateY(0);
    //   }

    //   50% {
    //     transform: translateY(5px);
    //   }
    // }

    @keyframes lineMovement {
      from {
        transform: scaleY(0);
      }

      to {
        transform: scaleY(1);
      }
    }
  }

  .logo-container {
    text-align: center;
    padding-left: 20px;
    display: flex;
    align-items: center;

    svg {
      font-size: 25px;
      padding: 0 20px;
    }
  }

  .hamburger-menu-content {
    position: relative;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: #2b2b2b;
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    width: 100%;
    transition: all 0.5s ease-in-out;

    span {
      position: relative;
    }

    svg {
      padding: 0;
    }
  }

  .theme-checkbox {
    --toggle-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 6.25em;
    height: 3.125em;
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #efefef), color-stop(50%, #2a2a2a)) no-repeat;
    background: -o-linear-gradient(left, #efefef 50%, #2a2a2a 50%) no-repeat;
    background: linear-gradient(to right, #efefef 50%, #2a2a2a 50%) no-repeat;
    background-size: 205%;
    background-position: 0;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    border-radius: 99em;
    position: relative;
    cursor: pointer;
    font-size: var(--toggle-size);
  }

  .theme-checkbox::before {
    content: "";
    width: 2.25em;
    height: 2.25em;
    position: absolute;
    top: 0.438em;
    left: 0.438em;
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #efefef), color-stop(50%, #2a2a2a)) no-repeat;
    background: -o-linear-gradient(left, #efefef 50%, #2a2a2a 50%) no-repeat;
    background: linear-gradient(to right, #efefef 50%, #2a2a2a 50%) no-repeat;
    background-size: 205%;
    background-position: 100%;
    border-radius: 50%;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
  }

  .theme-checkbox:checked::before {
    left: calc(100% - 2.25em - 0.438em);
    background-position: 0;
  }

  .theme-checkbox:checked {
    background-position: 100%;
  }

  .right-links {
    display: flex;
    gap: 20px;
    padding-right: 20px;

    a {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background-color: #222222;
      border-radius: 5px;
      text-decoration: none;
      color: #fff;
      transition: all 0.5s ease-in-out;

      &:hover {
        background-color: #1d1d1d;
      }

      svg {
        font-size: 30px;
      }

      .portfolio-logo {
        border: 3px solid;
        border-radius: 100%;
        color: #64ffda;
        font-family: Fira Code, monospace;
        font-size: 20px;
        height: 25px;
        display: flex;
        justify-content: center;
        width: 25px;
        align-items: center;
      }
    }
  }
}