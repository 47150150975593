/* * {
  box-sizing: border-box;
} */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.authContainer {
  max-width: 400px;
  margin: auto;
  padding: 20px;
}

.loggedInSection {
  text-align: center;
  padding: 20px;
}

.welcomeText {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}

.signOutButton {
  background: #ff5555;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
}

body {
  background: #1d1d1d;
  color: #fff;
  font-family: "Roboto", sans-serif;
  margin: 0;
}

a {
  color: #fff;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #292929;
}

/* 
::-webkit-scrollbar-thumb:hover {
  background: #555;
} */